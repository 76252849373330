export const TACHION_BASE_URL = "https://tachionframework.ch/tachionserver";
//export const TACHION_BASE_URL = "http://localhost:8080/tachionserver";
// export const TACHION_BASE_URL = "http://localhost:8080/tachionserver/resources/663/tachion";
export const TACHION_URL_SUFFIX = "/resources/663/tachion"

// set to true to log more information about loading status and processing details to console
export const DEBUG_LOGGING = true;

export const API_MODES = {
    first: "firstQuery",
    second: "defineTargets",
    third: "secondQuery",
    fourth: "tuneTargets",
    fifth: "fourthQuery"
};

export const PARAM_LABELS = {
    // First Query
    _building_constructionYear: "Baujahr",
    _site_userType: "Benutzertyp",
    _building_usageCategory2: "Zweit-Nutzung",
    _building_usageCategory2_area: "Zweit-Nutzung Fläche",
    _building_usageCategory3: "Dritt-Nutzung",
    _building_usageCategory3_area: "Dritt-Nutzung Fläche",
    _building_livingArea: "Wohnfläche",
    _building_temperatureSetting: "Raumtemperatur im Winter",
    _building_infiltration: "Gebäude-Luft-Infiltration",
    _solarSystem_installedArea: "Wie gross ist sie etwa?",
    _pvSystem_installedPower: "Wie viel Leistung hat sie etwa?",
    _energySystem_heating0: "Heizung",
    // _energySystem_hotwater0: "Warmwasser1",
    // _energySystem_hotwater1: "Warmwasser2",
    // _building_insulationStandard: "Energetische Sanierung",

    // Second Query
    _building_heatedExtensions: "Erweiterte Wohnfläche",
    _building_atticHeatedFraction: "Anteil Beheizter Dachstock",
    _building_basementHeatedFraction: "Anteil Beheizter Keller",
    _building_occupantNumber: "Anzahl Bewohner",
    _building_daysAtHome: "Tage tagsüber zuhause",
    _site_housingStatus: "Ausbaustandard",
    _building_ventilationType: "Lüftung",
    _energySystem_coolingType: "Kühlung im Sommer",
    _energySystem_airCondition: "Kühlung ab Temperatur",
    _equipment_stove: "Kochherde",
    _equipment_kitchenRenovation: "Küche erneuert im Jahr",
    _equipment_washingRenovation: "Waschmaschine aus dem Jahr",
    _energySystem_circulation: "Warmwasser-Zirkulation",
    _energySystem_heating0Renovation: "Letzte Heizungserneuerung"

};

export const DEFINE_LABELS = {};

export const MEASURES_SETTINGS = {
    // Background Colors for Yearly Measures Table Elements:
    // NOTE: make sure to only enable one set of Colors!
    // Color Grandients can be generated at https://coolors.co/ff0000-f47d00-efbb00-e9f900-75c50f-00911e
    // categoryColors: ["#FF0000", "#F47D00", "#EFBB00", "#E9F900", "#75C50F", "#00911E"], // flashy colors
    categoryColors: ["#D21C1C", "#E09D0D", "#E5CB07", "#E9F900", "#75C50F", "#00911E"], // red to green

    // categoryColors: ["#00911E", "#75C50F", "#E9F900", "#E5CB07", "#E09D0D", "#D21C1C"], // reverted: green to red
    // categoryColors: ["#BE270F", "#C86411", "#D6A213", "#E3DF15", "#A2C823", "#60B131"], // red to green V2
    // Flag for each Year that specifies whether white text color should be used, if false black is used:
    categoryWhiteTextColor: [true, true, false, false, true, true]
    // categoryWhiteTextColor: [true, true, false, false, false, true]
};
