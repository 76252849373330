import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import {
    selectDataLoading,
    selectDataSelectedAddress,
    selectDataActiveMeasuresBeforeYear,
} from '../../reducers/dataSelectors';
import { useNavigate } from 'react-router';
import { logMessage } from '../../lib/logging';
import { NavBreadcrumb } from '../layout/NavBreadcrumb';

export const Financing = () => {
    const selectedAddress = useSelector(selectDataSelectedAddress);
    const loading = useSelector(selectDataLoading);
    const navigate = useNavigate();

    const startYear = new Date().getFullYear() + 1;
    const maxYear = startYear + 6 - 1;

    const allMeasures = useSelector((state) =>
        selectDataActiveMeasuresBeforeYear(state, maxYear.toString(), false)
    );

    const [timelineNetSavings, setTimelineNetSavings] = useState(Array(31).fill(0));
    const [timelineCO2Savings, setTimelineCO2Savings] = useState(Array(31).fill(0));

    const takeSubMeasure = (subMeasure) => {
        return subMeasure.selection > 0 && subMeasure.selection < 4 && !defaultOption(subMeasure);
    };

    const defaultOption = (subMeasure) => {
        return subMeasure.value === subMeasure.subOption[0]?.uid;
    };

    const getOption2 = (subMeasure) => {
        return subMeasure.subOption.find((option) => option.uid === subMeasure.value);
    };

    // Update the total timeline for net savings and CO2 savings
    const updateTotalTimeline = () => {
        const netSavings = Array(31).fill(0);
        const CO2Savings = Array(31).fill(0);

        allMeasures.forEach((measure) => {
            measure.subMeasure.forEach((subMeasure) => {
                if (takeSubMeasure(subMeasure)) {
                    const optionCost = getOption2(subMeasure).cost;

                    // Check and accumulate over the timeline if present
                    if (optionCost.timelineNetSavings) {
                        for (let year = 0; year < 31; year++) {
                            if (!isNaN(optionCost.timelineNetSavings[year])) {
                                netSavings[year] += Number(optionCost.timelineNetSavings[year]) * 4.5;
                                CO2Savings[year] += Number(optionCost.timelineCO2Savings[year]) * 4.5;
                            }
                        }
                    }
                }
            });
        });

        for (let year = 1; year < 31; year++) {
            netSavings[year] += netSavings[year - 1];
            CO2Savings[year] += CO2Savings[year - 1];
        }

        setTimelineNetSavings(netSavings);
        setTimelineCO2Savings(CO2Savings);
    };

    const getSelectedSubOption = (subMeasure) => {
        return subMeasure.subOption.find((option) => option.uid === subMeasure.value);
    };

    const grossInvestment = allMeasures.reduce((total, item) => {
        return (
            total +
            item.subMeasure.reduce((subTotal, subMeasure) => {
                const selectedOption = getSelectedSubOption(subMeasure);
                return subTotal + (selectedOption ? Number(selectedOption.cost['grossInvestment']) : 0);
            }, 0)
        );
    }, 0);

    const subsidies = allMeasures.reduce((total, item) => {
        return (
            total +
            item.subMeasure.reduce((subTotal, subMeasure) => {
                const selectedOption = getSelectedSubOption(subMeasure);
                return subTotal + (selectedOption ? Number(selectedOption.cost['subsidies']) : 0);
            }, 0)
        );
    }, 0);

    const taxSavings = allMeasures.reduce((total, item) => {
        return (
            total +
            item.subMeasure.reduce((subTotal, subMeasure) => {
                const selectedOption = getSelectedSubOption(subMeasure);
                return subTotal + (selectedOption ? Number(selectedOption.cost['taxSavings']) : 0);
            }, 0)
        );
    }, 0);

    const [lentCapitalPercentage, setLentCapitalPercentage] = useState(20);
    const [years, setYears] = useState(5);
    const [interestRate, setInterestRate] = useState(15); // Percentage

    const handleLentCapitalChange = (event) => {
        setLentCapitalPercentage(Number(event.target.value));
    };

    const handleYearsChange = (event) => {
        setYears(Number(event.target.value));
    };

    const handleInterestRateChange = (event) => {
        setInterestRate(Number(event.target.value));
    };

    useEffect(() => {
        if (selectedAddress === undefined && !loading.first) {
            logMessage('no address selected - redirect to auswahl');
            navigate('/auswahl');
        }
    }, [selectedAddress, loading, navigate]);

    useEffect(() => {
        if (selectedAddress !== undefined && !loading.first) {
            updateTotalTimeline();
        }
    }, [selectedAddress, loading]);

    const categories = [
        'Gesamtinvestition (brutto)',
        'Förderbeiträge',
        'Steuereinsparungen',
        'Zinsen',
        'Gesamtinvestition (netto)',
        'Einsparungen',
        'Ertrag',
    ];

    // Calculate interest and adjust net investment
    const lentCapital = (grossInvestment * lentCapitalPercentage) / 100;
    const interest = lentCapital * (interestRate / 100);
    const netInvestment = grossInvestment - subsidies - taxSavings + interest;
    const netSavingsValue = timelineNetSavings[years - 1] || 0;

    // Prepare data for the waterfall chart
    const data = [
        -grossInvestment, // data[0]: Gross Investment (cost)
        subsidies,        // data[1]: Subsidies (gain)
        taxSavings,       // data[2]: Tax Savings (gain)
        -interest,        // data[3]: Interest (cost)
        0,                // data[4]: Net Investment (bar height is zero)
        netSavingsValue,  // data[5]: Net Savings
        0,                // data[6]: Ertrag (arrow)
    ];

    // Calculate cumulative sums
    let cumulativeSum = 0;
    const cumulativeSums = [0];

    data.forEach((value) => {
        cumulativeSum += value;
        cumulativeSums.push(cumulativeSum);
    });

    const finalCumulativeSum = cumulativeSums[6]; // Cumulative sum up to 'Einsparungen'
    const ertragArrowDirection = finalCumulativeSum >= 0 ? 'up' : 'down';
    const ertragArrowColor = finalCumulativeSum >= 0 ? 'blue' : 'red';

    // Arrow paths with shafts
    const upwardArrowPath = 'path://M10,30 L15,20 L12,20 L12,0 L8,0 L8,20 L5,20 Z';
    const downwardArrowPath = 'path://M10,0 L15,10 L12,10 L12,30 L8,30 L8,10 L5,10 Z';

    const ertragArrowSymbol =
        ertragArrowDirection === 'up' ? upwardArrowPath : downwardArrowPath;

    // Adjust chartHeight to 304
    const chartHeight = 304;

    // Function to format numbers
    function formatNumber(number) {
        const numberString = Math.round(number).toString();
        const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        return formattedNumber + '.-';
    }

    const roundDownToMultiple = (value, multiple) => Math.floor(value / multiple) * multiple;
    const roundUpToMultiple = (value, multiple) => Math.ceil(value / multiple) * multiple;

    const rawMin = Math.min(...cumulativeSums);
    const rawMax = Math.max(...cumulativeSums);

    // Ensure that zero is included in the Y-axis range
    const min = Math.min(0, roundDownToMultiple(rawMin, 50000));
    const max = Math.max(0, roundUpToMultiple(rawMax, 50000));

    const axisRange = max - min;

    const normalizedNetInvestmentArrowHeight = Math.abs(
        (netInvestment / axisRange) * chartHeight
    );

    const normalizedErtragArrowHeight = Math.abs(
        (finalCumulativeSum / axisRange) * chartHeight
    );

    const colorArray = [
        '#FFA500', // Gross Investment - Orange
        '#00FF00', // Subsidies - Green
        '#32CD32', // Tax Savings - Lime Green
        '#FFFF00', // Interest - Yellow
        '#FF0000', // Net Investment bar (invisible)
        '#1E90FF', // Net Savings - Dodger Blue
        ertragArrowColor, // Ertrag Arrow
    ];

    const getOption = () => ({
        tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' },
            formatter: function (params) {
                let result = '';
                params.forEach((item) => {
                    // Exclude NaN and undefined values and the Placeholder series
                    if (
                        item.seriesName !== 'Placeholder' &&
                        item.seriesName !== 'Connecting Lines' &&
                        item.data !== 0 &&
                        item.data !== undefined &&
                        !isNaN(item.data)
                    ) {
                        const value = Math.abs(item.data);
                        result += `${item.marker} ${item.name}: ${formatNumber(value)}<br/>`;
                    }
                });
                return result;
            },
        },
        grid: {
            left: '5%',
            right: '15%',
            top: '15%',
            bottom: '20%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: categories,
            axisLabel: {
                interval: 0,
                rotate: 30,
                fontSize: 12,
            },
        },
        yAxis: {
            type: 'value',
            min: min,
            max: max,
            interval: 50000, // Adjusted interval to 50,000
            axisLabel: {
                formatter: '{value}.-',
                fontSize: 12,
            },
        },
        series: [
            // Placeholder series to position bars
            {
                name: 'Placeholder',
                type: 'bar',
                stack: 'Total',
                itemStyle: {
                    borderColor: 'transparent',
                    color: 'transparent',
                },
                emphasis: {
                    itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent',
                    },
                },
                tooltip: {
                    show: false,
                },
                data: cumulativeSums.slice(0, -1),
                stackStrategy: 'all',
            },
            // Data series for actual values
            {
                name: 'Data',
                type: 'bar',
                stack: 'Total',
                stackStrategy: 'all',
                data: data,
                label: {
                    show: true,
                    position: 'inside',
                    formatter: ({ data }) => (data !== 0 ? formatNumber(Math.abs(data)) : ''),
                },
                itemStyle: {
                    color: (params) => colorArray[params.dataIndex],
                },
            },
            // Net Investment Arrow
            {
                name: 'Nettoinvestition',
                type: 'pictorialBar',
                symbol: downwardArrowPath,
                symbolSize: [40, normalizedNetInvestmentArrowHeight],
                symbolPosition: 'start',
                symbolOffset: [0, 0],
                data: data.map((value, index) =>
                    index === 4 ? cumulativeSums[4] : '-'
                ),
                itemStyle: {
                    color: '#FF0000',
                    borderColor: '#FF0000',
                    borderWidth: 1,
                },
                tooltip: {
                    formatter: function () {
                        const value = Math.abs(netInvestment);
                        return `<div style="color: #FF0000;">Nettoinvestition: ${formatNumber(value)}</div>`;
                    },
                },
                z: 10,
                label: {
                    show: false,
                },
            },
            // Ertrag Arrow
            {
                name: 'Ertrag',
                type: 'pictorialBar',
                symbol: ertragArrowSymbol,
                symbolSize: [40, Math.abs(normalizedErtragArrowHeight)],
                symbolPosition: 'end',
                symbolOffset: [0, 0],
                data: data.map((value, index) => {
                    if (index === 6) {
                        return finalCumulativeSum; // Start from zero and extend to cumulative savings
                    } else {
                        return '-';
                    }
                }),
                symbolRotate: ertragArrowDirection === 'up' ? 180 : 0,
                itemStyle: {
                    color: ertragArrowColor,
                    borderColor: ertragArrowColor,
                    borderWidth: 1,
                },
                tooltip: {
                    formatter: function () {
                        const value = Math.abs(finalCumulativeSum);
                        return `<div style="color: ${ertragArrowColor};">Ertrag: ${formatNumber(value)}</div>`;
                    },
                },
                z: 10,
                label: {
                    show: false,
                },
            },
            // Mark Lines to connect bars
            {
                name: 'Connecting Lines',
                type: 'line',
                data: [],
                markLine: {
                    symbol: 'none',
                    lineStyle: {
                        color: '#000000',
                        width: 1,
                        type: 'dashed',
                    },
                    data: categories.slice(0, -1).map((category, index) => [
                        { xAxis: categories[index], yAxis: cumulativeSums[index + 1] },
                        { xAxis: categories[index + 1], yAxis: cumulativeSums[index + 1] },
                    ]),
                },
                tooltip: {
                    show: false,
                },
            },
        ],
    });

    return (
        <>
            <NavBreadcrumb />
            <Container>
                <Row>
                    <Col>
                        <h3>Finanzierungsplan</h3>

                        {/* ECharts Waterfall Chart */}
                        <div className="d-flex justify-content-center">
                            <ReactECharts
                                option={getOption()}
                                style={{ height: '600px', width: '100%' }}
                                opts={{ renderer: 'svg' }} // Use SVG rendering
                            />
                        </div>

                        {/* Sliders to control the values */}
                        <div className="mt-4">
                            <label>Fremdkapital: {lentCapitalPercentage}%</label>
                            <input
                                type="range"
                                min="0"
                                max="80"
                                step="1"
                                value={lentCapitalPercentage}
                                onChange={handleLentCapitalChange}
                                className="form-range"
                                style={{ width: '100%' }}
                            />
                        </div>

                        <div className="mt-4">
                            <label>Jahre: {years}</label>
                            <input
                                type="range"
                                min="5"
                                max="30"
                                step="1"
                                value={years}
                                onChange={handleYearsChange}
                                className="form-range"
                                style={{ width: '100%' }}
                            />
                        </div>

                        <div className="mt-4">
                            <label>Zinssatz: {interestRate}%</label>
                            <input
                                type="range"
                                min="0"
                                max="15"
                                step="0.5"
                                value={interestRate}
                                onChange={handleInterestRateChange}
                                className="form-range"
                                style={{ width: '100%' }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
